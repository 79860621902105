import { Button, Form, Input } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {  Link, useNavigate } from 'react-router-dom'
import { dangerAlert, successAlert } from '../components/Alert'
import { login } from '../host/requests/Auth'
import { useStore } from '../store/Store'
import { getUserFilter, getUserProfile } from '../host/requests/User'
import { checkUsersData } from '../components/Checkers'
import login_img from '../new_images/main/mobile_back_img_login.jpg'
import { FaCheck } from 'react-icons/fa6'
import FooterMobile from '../components/FooterMobile'
import Cookies from 'js-cookie';
export default function Login() {
  const settoken_access=useStore(state=>state.settoken_access)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
    const setuser=useStore(state=>state.setuser)
    const user=useStore(state=>state.user)
    const setfilter=useStore(state=>state.setfilter)
    const setloader=useStore(state=>state.setloader)
    
    const {t}=useTranslation()
    const navigate=useNavigate()
    const setCookieWithExpiry = (key, value) => {
      const expirationTime = 1 / 24; 
      Cookies.set(key, value, { expires: expirationTime });
    };
    const  onFinish=async(val)=>{
      setloader(true)
      try{
        var res=await login(val)
        settoken_access(res.data.access)
      settoken_refresh(res.data.refresh)
      setCookieWithExpiry('access_token_bez', res.data.access);
      setCookieWithExpiry('refresh_token_bez', res.data.refresh);
         try{
          var res1=await getUserProfile(null, res.data.access)
          var res2=await getUserFilter(null, res.data.access)
          if(!checkUsersData(res1.data, res2.data)){
            successAlert(t("fill_user_data"))
          }
          
          setuser(res1.data)
          setfilter(res2.data)
          setloader(false)
          navigate('/cabinet')
          }catch (error){
            setloader(false)
          dangerAlert(t("server_error"))
         }
        
       }catch (error){
        setloader(false)
        if(!!error.response.data.detail){
          if(error.response.data.detail==="no_user_exists"){
            navigate("/register")
          }
          dangerAlert(t(error.response.data.detail))
        }else{
          dangerAlert(t("server_error"))}
      }
    }

    useEffect(()=>{
      if(user!=null){
        navigate('/cabinet')
      }
     }, [])
  
  return (
    <>
    <div className='login_big_box'>
       <div className='login_form'>
        <h1>{t("kirish")}</h1>
        <p>{t("welcome")}</p>
       <Form
    name="basic"
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    
  >
    <Form.Item
      name="email"
      rules={[
        {
          type: 'email',
          message: t("not_empty"),
        },
        {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input  className='login_input' placeholder={t("email")} />
    </Form.Item>

    <Form.Item
      name="password"
      rules={[
        {
          required: true,
          message: t("not_empty"),
        },
      ]}
    >
      <Input.Password className='login_input'  placeholder={t("password")} autoComplete="new-password"/>
    </Form.Item>
     <Form.Item
      wrapperCol={{
        span: 24,
      }}
    >
      <Button className='btn_form' htmlType="submit">
        {t("kirish")}
      </Button>
    </Form.Item>
  </Form>
  <div className='login_bottom'>
    <div className='login_check'>
    <label class="custom-checkbox">
    <input type="checkbox" />
    <span class="checkmark">
      <span className='checkmark_icon'><FaCheck /></span>
    </span>

</label>
      <p>{t("remember_me")}</p>
    </div>
    <Link className='forget_link' to="/change-password">{t("forget_password")}</Link>
  </div>
  <div className='login_bottom flex_left'>
  <Link className='forget_link' to="/register"><p dangerouslySetInnerHTML={{__html:t("have_not_account")}}></p></Link>
  </div>
       </div>
           <div className='login_img'>
               <img src={login_img} />
           </div>
       
           
    </div>
    <FooterMobile/></>
  )
}
