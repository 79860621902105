import React, { useRef, useState } from 'react'
import { useCookies } from 'react-cookie';
import i18n from '../locale/i18next';
import { useStore } from '../store/Store';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMoon } from "react-icons/fi";
import { FaBars, FaBarsStaggered,FaSun } from "react-icons/fa6";
import { VscGlobe } from "react-icons/vsc";
import logo from '../new_images/logo.png'
import { IoClose, IoSearch } from 'react-icons/io5';
export default function Navbar() {
  const [cookie, setCookie]=useCookies()
  const inputRef=useRef()
  const {t} =useTranslation()
  const setisBlue=useStore(state=>state.setisBlue)
  const setopen_menu=useStore(state=>state.setopen_menu)
  const open_menu=useStore(state=>state.open_menu)
  const like_count=useStore(state=>state.like_count)
  const chat_count=useStore(state=>state.chat_count)
  const search=useStore(state=>state.search)
  const setsearch=useStore(state=>state.setsearch)
  const open_search=useStore(state=>state.open_search)
  const setopen_search=useStore(state=>state.setopen_search)
  const isBlue=useStore(state=>state.isBlue)
  const user=useStore(state=>state.user)
  const navigate=useNavigate()
  
    const [til, settil] = useState(cookie.tilTanish?cookie.tilTanish:"uz");
    const changeLang=()=>{
      if(til==='uz'){
        i18n.changeLanguage("ru")
        settil("ru")
        setCookie("tilTanish", "ru")
      }else if(til==='ru'){
        i18n.changeLanguage("en")
        settil("en")
        setCookie("tilTanish", "en")
      }else  if(til==='en'){
        i18n.changeLanguage("uz")
        settil("uz")
        setCookie("tilTanish", "uz")
      }
  }
  return (
    <div className='navbar'>
        <Link to={user==null?'/':'/cabinet'} className='navbar_brand'>
             <img src={logo} />
        </Link>
        <div className='navbar_menu'>
          <ul>
            <li  className='link_li'><Link className='navbar_link' to={'/'}>{t("main")}</Link></li>
            <li  className='link_li'><Link className='navbar_link' to={'/support'}>{t("support")}</Link></li>
            <li  className='link_li'><Link className='navbar_link' to={'/'}>{t("aboutus")}</Link></li>
            {user!=null? <li  className='link_li'><Link className='navbar_link' to={'/cabinet'}>{t("users")}</Link></li>:<></>}
           
           
          </ul>
          <div className='navbar_right'>
          {user!=null?<div className={`search ${open_search?'open_search':""}`}>
        <input ref={inputRef} value={search} onChange={(e)=>{setsearch(e.target.value);}}  type='text' placeholder={t("search")}/>
        <div onClick={()=>{navigate("/cabinet"); if(open_search){setsearch('')}else{inputRef.current.focus()} setopen_search(!open_search);}} className={`searchBtn ${search.length>0?"active_text":""}`}>{open_search?<IoClose />:<IoSearch/>}</div>
        </div>:<></>}
              <button onClick={setisBlue} className='light_btn'>
                {isBlue?<FaSun />:<FiMoon />}
              </button>
              <button onClick={changeLang} className='lang_btn'>
              <VscGlobe /><span>{t("lang")}</span>
              </button>
              {user==null?<Link className='btn_login' to={"/login"}>{t("login")}</Link>:<button onClick={()=>{setopen_menu(!open_menu)}} className='btn_bar'>{open_menu?<FaBarsStaggered />:<FaBars />}{like_count+chat_count>0?<span className='navbar_badge'>{like_count+chat_count}</span>:<></>}</button>}
              
          </div>
        </div>
    </div>
  )
}
