import React, { useEffect, useState } from 'react'
import { BsQuestionOctagonFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useStore } from '../store/Store'
import { getfaqHttp } from '../host/requests/User'
import { useTranslation } from 'react-i18next'

export default function Setting() {

  const {t}=useTranslation()
  
  return (
    <div className='setting_box'>
  
    </div>
  )
}
