import React, { useState } from 'react'
import { useStore } from '../store/Store'

import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import place_icon from '../new_images/place_icon.svg'
import { formatDateList } from '../components/Checkers'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Chat from './Chat'
import { TbLock } from 'react-icons/tb'
import { getChatUsers } from '../host/requests/User'
import { PuffLoader } from 'react-spinners'
import { GoPlus } from 'react-icons/go'

export default function ChatBox() {
    const {chat_id}=useParams()
const chats=useStore(state=>state.chats)
const open_chat=useStore(state=>state.open_chat)
const token_access=useStore(state=>state.token_access)
const user=useStore(state=>state.user)
const chat_next=useStore(state=>state.chat_next)
const setopen_chat=useStore(state=>state.setopen_chat)
const number=useStore(state=>state.number)
const setloader=useStore(state=>state.setloader)
const setis_online=useStore(state=>state.setis_online)
const setchat_next=useStore(state=>state.setchat_next)
const setchat_page=useStore(state=>state.setchat_page)
const setchats=useStore(state=>state.setchats)
const chat_page=useStore(state=>state.chat_page)
const [chat_spin, setchat_spin]=useState(false)
const navigate =useNavigate()
const {t}=useTranslation()
const createChat=(item)=>{
    if(!(chat_id.indexOf(item.other_user.chat_id)!=-1)){
    
      setloader(true); 
      setis_online(false);
       setopen_chat(item); 
     
      navigate(user!==null?`/cabinet/chat/${item.name}`:"")
    }
  
  }

  const getChats=async()=>{
    if(chat_next!==null){
     
      try{
        var res=await getChatUsers({page:chat_page}, token_access)
      
        setchat_next(res.data.next)
        setchat_page(chat_page+1)
        setchats(res.data.results)
       
     }catch(error){
        console.log(error)
       
     }
    }
  }
  

  return (
    <div className='chat_big_box'>
      <p style={{display:'none'}}>{number}</p>
        <div className='chat_list'>
        <div className='chat_list_header'>{t("messages")}</div>
        <div className='chat_list_box'>
            
        {[...chats]!=null?[...chats].map((item, key)=>{
                return(<div onClick={()=>{createChat(item)}} key={key} className={`${chat_id.indexOf(item.other_user.chat_id)!=-1?"active_chat_item":""} chat_item`}>
                    {item.other_user!=null?<>
                    <div className='chat_item_user'>
                    <div className='avatar_box' style={{backgroundImage:`url(${item.other_user.main_image!=null?item.other_user.main_image:item.other_user.gender=='male'?man_icon:woman_icon})`}}></div>
                    <div className='chat_item_text'>
                        <h1>{item.other_user.first_name}</h1>
                        <p>{item.is_online?"online":formatDateList(item.other_user.last_login, t("error_api"))}</p>
                    </div>
                    </div>
                   
                    {item.unread_messages>0?<div className='chat_badge menu_badge'>{item.unread_messages}</div>:<></>}
                    {item.blocked_me?<span className='block_icon_menu' style={{color:'red'}}><TbLock/></span>:<></>}
                    </>:<></>}
                    
                    </div>)
             }):<></>}
             {chat_next!==null && chat_next!=="a"?
       <div className='addBtnBox'>
       {chat_spin?<div className='loadBtn'><PuffLoader /></div>: <button  onClick={()=>{getChats()}}><GoPlus/></button>}
      
     </div>
      :<></>}
        </div>
        </div>
        {chat_id!=="all"? <div className='chat_chat'>

<Chat chat_id={chat_id} />
</div>:<></>}
       
    </div>
  )
}
