import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { changePasswordEmail,login,registerEmail,resetPassword, sendEmailCodeHttp } from '../host/requests/Auth'
import { checkResponse } from '../host/Host'
import { dangerAlert, successAlert } from '../components/Alert'
import { useStore } from '../store/Store'
import login_img from '../new_images/main/mobile_back_img.jpg'
import FooterMobile from '../components/FooterMobile'
import { getUserFilter, getUserProfile } from '../host/requests/User'
import { checkUsersData } from '../components/Checkers'
import Cookies from 'js-cookie';
export default function ChangePassword() {
  const [form] = Form.useForm();
  
    const [type, setType]=useState(1)
   
    const [email, setemail]=useState(null)
    const [password, setpassword]=useState(null)
    const settoken_access=useStore(state=>state.settoken_access)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const setloader=useStore(state=>state.setloader)
    const user=useStore(state=>state.user)
    const {t}=useTranslation()
    const navigate = useNavigate();
   const codeForm=form;

   const setCookieWithExpiry = (key, value) => {
    const expirationTime = 1 / 24; 
    Cookies.set(key, value, { expires: expirationTime });
  };


   const  onFinish=async(val)=>{
    setemail(val.email)
    setpassword(val.password)
    console.log(val)
     setloader(true)
   try{
      var res=await registerEmail(val)
      setloader(false)
      setType(2)
    }catch (error){
      console.log(error, !!error.response.data.detail)
     
      if(!!error.response.data.detail){
        dangerAlert(t(error.response.data.detail))
      }else{
        dangerAlert(t("error_password"))
      }
      navigate('/login')
      setloader(false)
    }
  }

  const  sendCode=async(val)=>{
   
     setloader(true)
   try{
      var res=await sendEmailCodeHttp(val)
      loginFunc()
     }catch (error){
     
      if(!!error.response.data.detail){
        dangerAlert(t(error.response.data.detail))
      }else{
        dangerAlert(t("error_password"))
      }
      setloader(false)
    }
  }
  const  loginFunc=async()=>{
    setloader(true)
    try{
      var res=await login({email, password})
      settoken_access(res.data.access)
    settoken_refresh(res.data.refresh)
    setCookieWithExpiry('access_token_bez', res.data.access);
    setCookieWithExpiry('refresh_token_bez', res.data.refresh);
       try{
        var res1=await getUserProfile(null, res.data.access)
        var res2=await getUserFilter(null, res.data.access)
        if(!checkUsersData(res1.data, res2.data)){
          successAlert(t("fill_user_data"))
        }
        
        setuser(res1.data)
        setfilter(res2.data)
        setloader(false)
        navigate('/cabinet')
        }catch (error){
          setloader(false)
        dangerAlert(t("server_error"))
       }
      
     }catch (error){
      setloader(false)
      if(!!error.response.data.detail){
        if(error.response.data.detail==="no_user_exists"){
          navigate("/register")
        }
        dangerAlert(t(error.response.data.detail))
      }else{
        dangerAlert(t("server_error"))}
    }
  }

   
   useEffect(()=>{
    if(user!=null){
      navigate('/cabinet')
    }
   }, [])
  return (
    <>
    <div className='login_big_box'>
   
           {type===1?
              <div className='login_form'>
              <h1>{t("create_akkount")}</h1>
              <p>{t("register_text")}</p>
             <Form
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
         initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          
        >
          <Form.Item
            name="first_name"
            rules={[
             
              {
                 required: true,
                 message: t("not_empty"),
                  
              }
            ]}
          >
            <Input  className='login_input' placeholder={t("firstname")} />
          </Form.Item>
      
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t("not_empty"),
              },
              {
                 required: true,
                 message: t("not_empty"),
                  
              }
            ]}
          >
            <Input  className='login_input' placeholder={t("email")} />
          </Form.Item>
      
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("not_empty"),
              },
            ]}
          >
            <Input.Password className='login_input'  placeholder={t("password")} autoComplete="new-password"/>
          </Form.Item>
           <Form.Item
            wrapperCol={{
              span: 24,
            }}
          >
            <Button className='btn_form' htmlType="submit">
              {t("create_akkount")}
            </Button>
          </Form.Item>
        </Form>
      
        <div className='login_bottom flex_left'>
        <Link className='forget_link' to="/login"><p dangerouslySetInnerHTML={{__html:t("has_account")}} /></Link>
        </div>
             </div>
           :type===2?
           <div className='login_form'>
    <h1>{t("enter_code")}</h1>
       <Form
   name="basic1"
   labelCol={{
     span: 24,
   }}
   wrapperCol={{
     span: 24,
   }}
  initialValues={{
     remember: true,
   }}
   onFinish={sendCode}
   form={codeForm}
   
   autoComplete="new-password"
 >
  <Form.Item
  name="code"
  rules={[
    {
      required: true,
      message: t("not_empty"),
    },
  ]}
>
<Input.OTP  formatter={(str) => str.toUpperCase()} />

</Form.Item>
<div className='login_bottom flex_left'>
  <p onClick={()=>{codeForm.resetFields(); setType(1)}} className='login_bottom_text' dangerouslySetInnerHTML={{__html:t("not_code")}}></p>
  </div>
  <Form.Item
     wrapperCol={{
       span: 24,
     }}
   >
     <Button className='btn_form' type="primary" htmlType="submit">
       {t("send")}
     </Button>
   </Form.Item>
 </Form>
 
</div>
       :<></>}
        
        
         <div className='login_img'>
               <img src={login_img} />
           </div>
           
      </div>
      <FooterMobile/></>
  )
}
