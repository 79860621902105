import { Button, Carousel } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {IoMdCloseCircle, IoMdArrowForward, IoMdArrowBack} from 'react-icons/io'
import {TbLock} from 'react-icons/tb'
import { useTranslation } from 'react-i18next'
import {Link, useNavigate } from 'react-router-dom'
import { Carousel as Car } from 'react-bootstrap'
import { useStore } from '../store/Store'
import { getUserForChat, getUsersDataRandom, setLike } from '../host/requests/User'
import { dangerAlert, successAlert } from '../components/Alert'

import place_icon from '../new_images/place_icon.svg'
import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { FaHeart, FaLock, FaRegHeart } from 'react-icons/fa6'
export default function ProfileCards() {
    const {t}=useTranslation()
    const profileRef=useRef()

    const setloader=useStore(state=>state.setloader)
    const ids=useStore(state=>state.ids)
    const setids=useStore(state=>state.setids)
  const [images, setimages]=useState(null)
  const token_access=useStore(state=>state.token_access)
  const next=useStore(state=>state.next)
  const setopen_chat=useStore(state=>state.setopen_chat)
  const setchatsfirst=useStore(state=>state.setchatsfirst)
  const setNext=useStore(state=>state.setNext)
  const page_size=useStore(state=>state.page_size)
  const setpage_size=useStore(state=>state.setpage_size)
  const active_id=useStore(state=>state.active_id)
  const setactive_id=useStore(state=>state.setactive_id)
  const user=useStore(state=>state.user)
  const navigate =useNavigate()
  const random_users=useStore(state=>state.random_users)
  const setrandomlike=useStore(state=>state.setrandomlike)
  const setrandomusers=useStore(state=>state.setrandomusers)
   
  useEffect(()=>{

    if(active_id===null){
    setactive_id(0)
    getRandomUsers(page_size)
    }else{
  
       if(profileRef.current){
        profileRef.current.goTo(active_id!==null?active_id:0)
       }
        
    }
   },[active_id])
  const handleKeyPress = (e) => {
   
    if (e.key === 'ArrowRight') {
        nextProfile()
    } else if (e.key === 'ArrowLeft') {
        prevProfile()
      }
      else if (e.key === 'Enter') {
        var id=document.querySelector(`.none_${active_id} .id`).innerHTML
        var key=Number(document.querySelector(`.none_${active_id} .key`).innerHTML)
        var bol=document.querySelector(`.none_${active_id} .bol`).innerHTML==='true'
        likeUser(id, key, bol)
      }
  };
  const getRandomUsers=async(page)=>{
    if(page==1){
      setloader(true)
    }
    try{
       
     var res=await getUsersDataRandom({page:page}, token_access)
     setrandomusers(res.data.results)
     setNext(res.data.next)
     setloader(false)
    }catch(error){
     setloader(false)
     console.log(error)
    }
  }

    const nextProfile=()=>{
       setids(null)
        if(active_id<random_users.length-1){
            setactive_id(active_id+1)
            if(active_id+1===random_users.length-3){
                if(next!==null){
                    getRandomUsers(page_size+1)
                    setpage_size(page_size+1)
                }
              }
           }
        
    }
    const prevProfile=()=>{
        setids(null)
        if(active_id>0){
            setactive_id(active_id-1)
        }
       
    }
   const covertWordUser=(gender, age)=>{
    if(t("lang")==='UZ'){
        return(`${age[0]} yoshdan ${age[1]} yoshgacha bo'lgan ${gender}ni izlayapti`)
    }else if(t("lang")==='РУ'){
        return(`Ищет ${gender} от ${age[0]} до ${age[1]} лет`)
    }else if(t("lang")==='EN'){
        return(`Looking for a ${gender} aged between ${age[0]} and ${age[1]}`)
    }
   }
   const likeUser=async(id, key, bol)=>{
    if(!bol){
        try{
            var res=await setLike({user:id}, token_access)
            setrandomlike(key)
            setids(key)
            successAlert(t("like_pushed"))
  
      }catch(error){
          dangerAlert(t("serverr_error_text"))  
      }
    }
  
   }
   const returnlike=(a)=>{
    var g=a.filter(x=>x.reaction==='liked')
    return(g.length>0)
   }
   const returnblock=(a, id_user)=>{
    var g=a.filter(x=>x.reaction==='blocked' && user==id_user)
    return(g.length>0)
   }
  const goToChat=async(item)=>{
    
   
        setloader(true)
       var chat_name=[user.chat_id, item.chat_id].sort()[0]+"__"+[user.chat_id, item.chat_id].sort()[1]
      var res = await getUserForChat({user:item.id}, token_access)
      console.log(res.data)
       setchatsfirst(res.data)
       setopen_chat(res.data)
       navigate("/cabinet/chat/"+chat_name)
   
  } 
  const checkBlockedMe=(a)=>{
    var h=a.filter(x=>x.reaction==='blocked' && x.user===user.id)
    return(h.length!==0)
}
  return (
    <>

    {random_users.length!==0?
     <div className='profile_box'
      onKeyDown={handleKeyPress}
      >
     <Carousel  accessibility={true} effect="fade" dots={false} initialSlide={active_id!==null?active_id:0} swipeToSlide={true}
      swipe={true} ref={profileRef} draggable={true} className='profile_carusel'>
     {[...random_users].map((item,key)=>{
         return( <div key={key}>
            
             <div className='profile_card'>
             {checkBlockedMe(item.reactions)?<div className='block_icon'><TbLock /></div>:<></>}
            
             <Link to={"/cabinet/user/"+item.id} className='profile_img' style={{backgroundImage:`url(${item.main_image!=null?item.main_image.media:item.gender=='male'?man_icon:woman_icon})`}}></Link>
             {/* <Carousel draggable={true} swipeToSlide={true} autoplay={false} dotPosition='top' className='profile_carusel carousel_img'>
                 {item.images!==null && item.images.length>0?
                 item.images.map((item1,key1)=>{
                     return( <div key={key1}>
                         <Link to={"/cabinet/user/"+item.id} className='profile_img' style={{backgroundImage:`url(${item1})`}}></Link>
                     </div>)
                 })
                 :
                 <div>

                 <div className={`profile_img`} style={{backgroundImage:`url(${item.gender=='male'?man_icon:woman_icon})`}}></div>
             </div>}
              
               
             </Carousel> */}
           <div className='profile_text'>
               <div className='profile_name'>{item.first_name}, {item.age}</div>
               <div className='profile_address'><p>{item.state!=null? item.state[`name_${t('error_api')}`]:''}</p> <img src={place_icon} /></div>
              
           </div>
          
           <Button onClick={()=>{goToChat(item)}} className='btn_form' htmlType="submit">
        {t("write")}
      </Button>
             </div>
            
             <div className='profile_btn_box'>
     {active_id>0?<button onClick={prevProfile} className='prevBtn btn_prof'><IoMdArrowBack className='unliked_heart'/></button>:<div className='btn_pfof_div'></div>}
     {<button  onClick={()=>{likeUser(item.id, key, returnlike(item.reactions) || ids===key)}} className={`btn_prof ${returnlike(item.reactions) || ids===key?"liked_box":""}`}>{returnlike(item.reactions) || ids===key?<FaHeart  className='btn_heart liked_heart' />:<FaRegHeart   className='btn_heart unliked_heart'/>}</button>}
     {active_id<random_users.length-1? <button onClick={nextProfile} className='nextBtn btn_prof'><IoMdArrowForward  className='unliked_heart'/></button>:<div className='btn_pfof_div'></div>}
    
    </div>
   
    </div>)
     })}
    
  </Carousel>

     </div>
    :<div className='listBox empty_user'>
    <div className='emptyList'></div>
</div>}

    </>
  )
}
