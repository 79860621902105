
import { useTranslation } from 'react-i18next'


export default function FooterMobile() {
  const {t} =useTranslation()
 
  return (
    <div className='footer_mobile'>
       <p>{t("footer_text_mobile")} <a href='#' target='_blank'>{t("footer_link_mobile")}</a></p>
    </div>
  )
}
